.left {
  background-color: beige;
}

.personal-details-short {
  .text-country {
    @media screen and (min-width: $medium-start) {
      width: 280px;
    }
  }
  .registration-form {
    @media screen and (min-width: $medium-start) {
      width: 100%;
    }
    .country-select {
      padding: 0;
    }
  }
}

.select-team-container {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $large-end) {
    margin-left: 15px !important;
    max-width: 1140px !important;
  }
  @media screen and (max-width: $small-end) {
    .players-list-stats {
      padding: 0 50px 20px;
    }
    .font-size-36 {
      font-size: 30px !important;
    }
    margin-bottom: 40px;
    margin-left: 0 !important;
  }
  .deadline-text {
    font-size: 15px;
    @media screen and (max-width: $small-end) {
      font-size: 12px;
    }
  }
  .deadline-text-date {
    @media screen and (max-width: $medium-end) {
      padding-bottom: 15px;
    }
  }
  .registration-form {
    @media screen and (max-width: $small-end) {
      padding-right: 40px;
      padding-left: 40px;
      .registration-form {
        margin-bottom: 0;
      }
    }
  }
  h1 {
    @media screen and (max-width: $medium-end) {
      margin-bottom: 38px;
    }
    @media screen and (max-width: $small-end) {
      margin-bottom: 35px;
    }
  }
  .select-team-button {
    @media screen and (max-width: $small-end) {
      margin-top: 0;
    }
  }
}

.select-team-left {
  min-width: 290px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.25);
  border: 2px solid $color-light-gray;
  @media screen and (max-width: $medium-start) {
    min-width: 240px;
    position: relative;
    top: 0;
    width: 100%;
    margin-top: 0 !important;
  }
}

.search-player-textfield {
  display: flex;
  align-items: center;
  justify-content: center;
  resize: none;
  font-size: 15px;
  border-radius: unset;
  width: 100%;
  @extend .source-sans-pro;
  text-align: center;
  padding: 10px 0 0;
  height: 45px !important;
  margin-bottom: 10px;
  &::placeholder {
    color: $color-secondary-text;
  }
  &.mobile {
    padding: 15px 0;
    height: 55px !important;
    margin: 0 0 10px;
  }
  border: none;

  background: url('../assets/images/search-normal.svg') no-repeat;
  background-size: 18px 18px;
  background-position: 38% 45%;

  &.active {
    background: none;
  }
}

.team-name-textfield {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  resize: none;
  font-size: 20px;
  border-radius: unset;
  color: black;
  width: 50%;
  @extend .source-sans-pro;
  font-weight: 600;
  text-align: center;
  height: 50px !important;
  border: none;
  border-bottom: 3px solid #D1D1D1;
  padding: 15px;
  background: transparent;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: $medium-end) {
    width: 65%;
  }
  @media screen and (max-width: $small-end) {
    width: 100%;
  }
  &.with-error {
    border-bottom-color: red;
  }
}

.team-name-textfield:focus {
  background: transparent;
}

.buttons-container {
  height: 30px;
  border: 2px solid $color-light-gray;
  width: 90%;
}

.selected-button {
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .source-sans-pro;
  font-size: 13px;
  font-weight: 600;
  color: $color-accent1;
  background-color: #FFFFFF;
  cursor: pointer;
  &__active {
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .source-sans-pro;
    font-size: 13px;
    font-weight: 600;
    background-color: $color-accent1;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.select-team-picker-container {
  margin-top: 30px;
  position: relative;
  width: 85%;
  .dropdown-menu {
    background-color: #FFFFFF;
    padding: 0;
    min-width: 100%;
    max-height: 300px;
    overflow: hidden;
    border: 1px solid #D1D1D1 !important;
    .scrollbar-container {
      background-color: #FFFFFF;
      height: 300px !important;
      width: 100% !important;
    }
  }
}

.select-team-picker-short-container {
  margin-top: 30px;
  margin-bottom: 10px;
  position: relative;
  width: 85%;
  .dropdown-menu {
    background-color: #FFFFFF;
    padding: 0;
    min-width: 100%;
    max-height: 300px;
    overflow: hidden;
    border: 1px solid #D1D1D1 !important;
    .scrollbar-container {
      background-color: #FFFFFF;
      height: 100%;
      width: 100% !important;
    }
  }
}

.basic-selectpicker {
  position: relative;
  border-bottom: 2px solid #D1D1D1;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .source-sans-pro;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: black;
}

.selectpicker-item {
  color: black;
  @extend .source-sans-pro;
  font-size: 16px;
  text-transform: capitalize;
}

.selectpicker-item:hover {
  color: $color-accent1;
}

.select-team-slider-container {
  margin-top: 60px;
  width: 85%;
}

.position-orange {
  width: 100%;
  height: 32px;
  background: #ECC435;
  display: flex;
  align-items: center;
  justify-content: center;
}

.players-table {
  height: 628px;
  min-height: 628px;
  max-height: 628px;
  width: 100%;
  @media screen and (max-width: $small-start) {
    >div {
      margin-right: 0 !important;
    }
  }
}

.players-table-item {
  height: 63px;
  border-bottom: 1px solid #F2F2F2;
  display: flex;
  flex-direction: row;
  background-color: white;

  .select-text-wrapper {
    div {
      height: 36px;
    }
  }

  &.selected-player {
    background-color: $color-e5;
    animation: selectPlayerAnimation 0.5s ease-in-out;
  }

  @keyframes selectPlayerAnimation {
    0% {
      background-color: white;
    }
    100% {
      background-color: $color-e5;
    }
  }
}

.players-info-section {
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2px;
  height: 100%;
  .info-icon {
    cursor: pointer;
  }
}

.gray-middle {
  width: 6px;
  height: 100%;
}

.player-status-color {
  &_gray {
    background-color: #D1D1D1;
  }
  &_red {
    background-color: #C40000;
  }
  &_yellow {
    background-color: #D8D800;
  }
}

.players-data-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 0 8px;
  cursor: pointer;
}

.football-wear {
  width: 26px;
  max-height: 32px; // or height: 32px; ?
  margin-right: 18px;
  @media screen and (max-width: $large-end) {
    display: none;
  }
  @media screen and (max-width: $small-end) {
    display: unset;
  }
}

.player-item-name {
  max-width: 130px;
  @media screen and (max-width: $medium-end) {
    max-width: 140px;
  }
  @media screen and (max-width: $medium-start) {
    max-width: 110px;
  }
  @media screen and (max-width: $small-end) {
    max-width: 140px;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}

.select-team-bottom-controls {
  z-index: 5;
  align-items: center;
  &.mb {
      @media screen and (max-width: $small-end) {
        margin-bottom: 40px;
      }
  }
}

.select-team-bottom-controls-buttons {
  align-items: flex-end;
  @media screen and (max-width: $small-end) {
    margin-top: 15px !important;
    align-items: center !important;
    justify-content: center !important;
  }
  button {
    width: 85%;
  }
}

.team-name-text {
  @extend .font-h3;
  overflow-wrap: anywhere;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: $large-end) {
    font-size: 32px;
  }
  @media screen and (max-width: $medium-end) {
    font-size: 30px;
  }
  &.mb {
      @media screen and (max-width: $small-end) {
        margin-bottom: 40px;
      }
  }
}


.select-team-top-controls {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 60px;
  margin-top: 40px;
  padding: 0 25px;
  &.with-wildcard-reason {
    position: relative;
  }
  @media screen and(max-width: $large-end) {
    width: 85%;
  }
  @media screen and (max-width: $medium-end) {
    padding: 0 0 25px;
    >button {
      justify-content: center;
    }
    margin-bottom: 0;
    width: 100%;
    >:nth-child(1) { order: 2; }
    >:nth-child(2) {
      order: 1;
      width: 100%;
    }
    >:nth-child(3) { order: 3; }

    &.with-wildcard-reason {
      >:nth-child(3) {
        order: 1;
        width: 100%;
      }
      >:nth-child(4) { order: 3; }
    }
    .hidden-button {
      display: none;
    }
  }
  @media screen and (min-width: $medium-start) and (max-width: $medium-end) {
    >button {
      width: 45% !important;
    }
    justify-content: space-around;
    .select-team-button {
      width: 80% !important;
    }
  }
  @media screen and (max-width: $small-end) {
    margin-top: 0 !important;
    justify-content: center;
    >button {
      max-width: 45% !important;
      margin: 0 15px;
    }
  }
  @media screen and (max-width: $extra-small-end) {
    >button {
      margin: 0 10px;
    }
  }
}

.select-team-button {
  >img {
    margin: 0 5px;
  }
  width: 30%;
  min-width: 20%;
  @media screen and (max-width: $small-end) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 17px;
  }
}

.player-list-item-points {
  min-width: 60px;
  justify-content: flex-end;
}

.mobile-players-list {
  max-height: 70vh;
  min-width: 80%;
  align-items: center;
}

.modal-container-players-list {
  width: unset;
  display: flex;
  justify-content: center;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.modal-wrapper-players-list {
  position: absolute;
  background: #FFFFFF;
  border-radius: 0 !important;
  align-items: center;
  width: 100%;
  min-width: 80vw;
  top: 0;
}

.hidden-modal {
  visibility: hidden;
  max-height: 1px;
  max-width: 1px;
  z-index: 0;
}

.players-list-stats {
  padding: 0 30px;
  justify-content: space-between;
  @media screen and (max-width: $medium-start) {
    max-width: 290px;
    width: 100%;
  }
  @media screen and (max-width: $small-start) {
    width: 80%;
  }
}

.select-team-left-block {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 5;
  min-width: 290px;
  @media screen and (max-width: $medium-start) {
    min-width: 240px;
  }
}

.live-previous-left {
  min-width: 300px;
  background-color: #FFFFFF;
  >div:not(:last-child) {
    margin-bottom: 40px;
  }
  &.out-page-mobile {
    background-color: transparent;
  }
  @media screen and (max-width: $medium-start) {
    position: relative;
    top: 0;
    width: 100%;
    margin-top: 0 !important;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 500px;
  }

  @media screen and (max-width: $small-end) {
    margin-left: auto;
    margin-right: auto;
  }
}

.transfers-modal-container {
  width: 660px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: $small-end) {
    width: 280px;
  }
}

.transfers-modal-players-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: $small-end) {
    padding-top: 10px;
    flex-direction: column;
    >:nth-child(1){
      justify-self: flex-start;
      margin-bottom: 20px;
    }
    >:nth-child(2) { padding-left: 60px; }
  }
}

.transfers-modal-header-yellow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: $small-end) {
    flex-direction: column;
    >:nth-child(1) { align-self: flex-start; }
    >:nth-child(2){
      align-self: flex-end;
      margin-top: 10px;
    }
  }
}

.transfers-modal-subheader-yellow {
  @extend .font-m4;
  width: 325px;
  height: 24px;
  background-color: #ECC435;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $small-end) {
    width: 250px;
    height: 20px;
    font-size: 10px;
  }
}

.players-transfer-item {
  width: 325px;
  @media screen and (max-width: $small-end) {
    width: 280px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.players-transfer-item-arrow {
  width: 60px;
  height: 60px;
  @media screen and (max-width: $small-end) {
    width: 46px;
    height: 46px;
  }
}

.players-transfer-item-team-logo {
  width: 64px;
  height: 42px;
  min-width: 64px;
  min-height: 42px;
  padding: 0 15px;
}

.players-transfer-item-player-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  >:nth-child(1) { @extend .font-m1; }
  >:nth-child(2) {
    @extend .source-sans-pro;
    font-size: 18px;
    font-weight: 600;
    color: $color-main-text;
  }
  >:nth-child(3) {
    @extend .source-sans-pro;
    font-size: 15px;
    color: $color-secondary-text;
  }
  @media screen and (max-width: $small-end) {
    >:nth-child(1) { font-size: 15px; }
    >:nth-child(2) {
      font-size: 13px;
    }
    >:nth-child(3) {
      font-size: 11px;
    }
  }
}

.transfers-modal-players-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: $small-end) {
    margin-top: 0;
  }
}

.live-previous-running-round {
  top: 88px;
  position: absolute;
  display: flex;
  justify-content: center;
  @media screen and (max-width: $medium-end) {
    top: 78px;
  }
  @media screen and (max-width: $small-end) {
    top: 38px;
  }
}