.player-info-modal {
  width: 1140px !important;
  max-width: 90vw;
  min-width: 320px;
  height: auto !important;
  @media screen and (max-width: $small-end) {
    padding: 40px 10px !important;
  }
  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    @media screen and (max-width: $small-end) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &_left {
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: $small-end) {
        height: 45px;
      }
      &_info {
        @media screen and (max-width: $small-end) {
          >div:nth-child(1) {
            font-size: 15px;
          }
        }
        height: 100%;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    &_right {
      height: 47px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      text-align: end;
      >div:nth-child(1) {
        @extend .font-h4;
      }
      >div:nth-child(2) {
        @extend .font-btn;
        color: $color-secondary-text;
      }
      @media screen and (max-width: $small-end) {
        text-align: center;
        margin-top: 35px;
        height: 40px;
        >div:nth-child(1) {
          font-size: 20px !important;
        }
        >div:nth-child(2) {
          font-size: 15px !important;
        }
      }
    }
  }
  &_table {
    @extend .source-sans-pro;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    border: 1px solid lightgrey;
    margin: 0 !important;
    min-width: 1079px;
    @media screen and (max-width: $extra-small-end) {
      font-size: 13px;
    }
    tr>td:nth-child(1), tr>td:nth-child(2), tr>td:last-child, tr>th:nth-child(1), tr>th:nth-child(2), tr>th:last-child {
      div {
        width: 40px;
        max-width: 40px;
        min-width: 40px;
      }
    }
    th,
    td {
      padding-right: 0;
      padding-left: 0;
      text-align: center;
      vertical-align: middle !important;
      div {
        width: 40px;
        margin-left: 12px;
        margin-right: 12px;
      }
    }

    thead, tfoot {
      position: sticky;
      z-index: 2;
    }

    thead, tbody, tfoot{
      tr {
        th, td {
          &:nth-child(1), &:nth-child(2) {
            position: sticky;
            inset-inline-start: 0; /* stick to the left */
            z-index: 1;
          }
          &:nth-child(2) {
            inset-inline-start: 64.5px; /* stick to the left */
          }
        }
      }
    }

    thead {
      background-color: $color-accent2;
      inset-block-start: -1px; /* stick to the top */
      th {
        background-color: $color-accent2;
        cursor: help;
        position: relative;
        padding-right: 0 !important;
        padding-left: 0 !important;
        margin-left: 0;
        margin-right: 0;

        >div:last-child {
          position: relative;
        }
      }
    }

    tbody {
      td {
        background-color: white;
      }
      tr:first-child {
        border-top: none;
      }
    }

    tfoot {
      background-color: $color-accent1;
      inset-block-end: -1px; /* stick to the bottom */
      td {
        background-color: $color-accent1;
        color: white !important;
      }
    }

    &_score {
      color: $color-secondary-text;
    }
    &_top-text {
      margin-bottom: 5px;
    }

    &.fixtures {
      min-width: unset;
      margin: 0 auto !important;
      max-width: 770px;

      th, td {
        cursor: unset;
        text-align: center;
        height: 46px;
        &:nth-child(1), &:nth-child(2) {
          width: 150px;
        }

        @media screen and (max-width: $small-end) {
          height: 38px;
          &:nth-child(1), &:nth-child(2) {
            width: 70px;
          }
        }
      }
    }

    &.past-season-results {
      thead, tbody, tfoot{
        tr {
          th, td {
            &:nth-child(2) {
              position: unset;
              inset-inline-start: unset;
              z-index: unset;
            }
          }
        }
      }
    }
  }

  .tabs {
    margin: 20px auto 0;
    display: flex;
    gap: 10px;
    .tab {
      padding: 0 5px 5px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      border: none;
      border-bottom: 2px solid transparent;
      background-color: unset;
      @extend .font-h7;
      text-align: center;
      width: 80px;
      @media (min-width: $medium-start) {
        width: 120px;
        font-size: 24px !important;
      }
      &:hover {
        @media (min-width: $medium-start) {
          color: $color-accent2;
          border-bottom: solid 2px $color-accent1;
        }
      }
      &.active {
        color: $color-accent1;
        border-bottom: 2px solid $color-accent1;
      }
    }
  }
}

.table-container {
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100dvh - 355px);
}

.table-outer {
  position: relative;

  &::before {
    z-index: 3;
    content: '';
    position: absolute;
    left: 129px;
    top: 0;
    width: 2.5px;
    height: 100%;
    background-color: $color-light-gray;
  }

  &.past-season-results {
    &::before { 
      left: 64.5px;
    }
  }
}

.player-info-text {
  @extend .source-sans-pro;
  color: $color-main-text;
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  @media screen and (max-width: $small-end) {
    font-size: 13px;
  }

  display: flex;
  align-items: center;
  .price-change {
    height: 10px;
  }
}

.player-info-subtext {
  @extend .source-sans-pro;
  color: $color-secondary-text;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  text-transform: capitalize;
  @media screen and (max-width: $small-end) {
    font-size: 11px;
  }
}

.player-shirt {
  max-width: 34px;
  max-height: 42px;
  min-width: 34px;
  min-height: 42px;
  @media screen and (max-width: $medium-end) {
    max-width: 34px;
    max-height: 42px;
    min-width: 34px;
    min-height: 42px;
  }
}

.players-data-section {
  justify-content: space-between;
  padding: 0 18px !important;
  @media screen and (max-width: $small-end) {
    padding: 0 12px !important;
  }
}

.player-list-item-points {
  justify-content: flex-end !important;
  min-width: 60px;
}

.highlighted-player .football-player-wrapper{
  background-color: rgba(144, 196, 238, 0.5);
  border-radius: 20%;
  background-size: 115%;
}

.active-player .football-player-wrapper{
  background-color: rgba(144, 238, 144, 0.5);
  border-radius: 20%;
  background-size: 115%;
}
.player-info-title {
  position: absolute;
  background-color: black;
  color: white;
  min-width: 100px !important;
  left: -45px;
  z-index: 999;
  padding: 3px;
}
