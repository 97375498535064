.leagues-page {
    .buttons-row {
        margin-top: 20px;
        margin-bottom: 30px;
        >.col:first-child {
            text-align: end;
        }
        >.col:last-child {
            text-align: start;
        }
    }
	.boards-row {
	    margin-bottom: 60px !important;
	}
	.button_medium {
		width: 100% !important;
		max-width: unset !important;
		min-width: unset !important;
		@media screen and (min-width: $medium-start) {
		    max-width: 300px !important;
		}
	}
}
.leagues-board__league-item {
	min-height: 55px;
	max-height: 55px;

	&.empty-private {
		max-height: none;
	}
	&.isSpinnerWrapper {
	    background-color: white;
		min-height: 100%;
		max-height: 100%;
	}
	&.private {
		a:focus, div:focus-visible {
			outline: none;
		}
	}
}
.leagues-board__points {
	min-width: 20px;
	max-width: 20px;
	padding: 0;
	text-align: right;
	span {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.leagues-board__members, .leagues-board__name {
	span {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.leagues-board__name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	flex-direction: row;
	word-break: break-word;

	.emoji {
		font-size: 12px;
		margin-right: 1px;
	}
}

.leagues-ranking {
    &__title {
        margin-bottom: 30px;
    }
	&__container {
		@media screen and (min-width: $extra-large) {
			max-width: 960px;
		}
	}
	&__league-name {
        @extend .source-sans-pro;
        @extend .font;
        font-weight: 600;
        font-size: 24px;
		text-align: center;
		color: #000;
		margin-bottom: 8px;
		@media screen and (min-width: $medium-start) {
			font-size: 30px !important;
		}
	}
	&__total-members {
		@extend .font-m2;
		margin-bottom: 30px;
		text-align: center;
		color: $color-main-text;
		@media screen and (min-width: $medium-start) {
			font-size: 20px !important;
		}
	}
	&__errors-wrapper {
		div {
			max-width: 620px;
            margin-bottom: 20px;
		}
	}
	&__row {
		align-items: center;
        margin-bottom: 30px;
	}
	&__code {
		@extend .font-l1;
		text-align: center;
		margin-bottom: 30px;

		img {
			height: 18px;
			width: 18px;
			@media screen and (min-width: $medium-start) {
				height: 21.5px;
				width: 21.5px;
			}
		}
	}
	.settings-icon {
		height: 30px;
		width: 30px;
		cursor: pointer;
		border-radius: 85%;

		@media screen and (min-width: $medium-start) {
			height: 40px;
			width: 40px;
		}

		&:hover {
			box-shadow: 0 0 15px $color-accent1;
			background-color: rgba(0, 143, 215, 0.35);
		}
	}
	.count-from-info {
	    font-size: 13px;
		img {
			height: 18px;
			width: 18px;
			@media screen and (min-width: $medium-start) {
				height: 20px;
				width: 20px;
			}
		}
	}
	.css-dyr6gj-container {
		max-width: 280px;
		margin: 0 auto;
	}
	.css-2b097c-container {
		padding: 0 30px;
		@extend .font-m1;
		@media screen and (min-width: $medium-start) {
			padding: 0 19px;
		}
	}
	&__pagination {
	    border: unset !important;
		justify-content: center;
	}
}

.leagues-table-wrapper {
    margin-bottom: 20px;
    >table:last-child {
        thead {
            display: none;
        }
    }
}

.leagues-table {
	width: 100%;
	@extend .font-13pts;

	&__row-item {
		border: 1px solid $color-light-gray;
		display: table-row;
		color: unset;
		&:hover {
			color: unset;
		}
		&_own {
			td {
				box-shadow: 0 10px 10px -10px $color-accent2 inset, 0 -10px 10px -10px $color-accent2 inset;
				&:nth-child(1) {
					box-shadow: 0 10px 10px -10px $color-accent2 inset, 0 -10px 10px -10px $color-accent2 inset, 10px 0 10px -10px $color-accent2 inset;
				}
				&:last-child {
					box-shadow: 0 10px 10px -10px $color-accent2 inset, 0 -10px 10px -10px $color-accent2 inset, -10px 0 10px -10px $color-accent2 inset;
				}
			}
		}
		cursor: pointer;
	}
	th {
		background-color: $color-light-gray;
		color: $color-main-text;
		padding-top: 10px;
		padding-bottom: 10px;
		vertical-align: middle;
		font-weight: 700;
	}
	td {
		padding-top: 15px;
		padding-bottom: 15px;
		vertical-align: middle;
		font-weight: 400;
	}
	&__cell-first {
		padding-left: 8px;
		@media screen and (min-width: $large-start) {
			padding-left: 20px;
		}
        .place-number-th {
            border-right: 2px solid $color-d1;
        }
        .place-number-td {
            font-weight: 600 !important;
            border-right: 2px solid $color-light-gray;
        }
	}
	&__cell-middle {
		width: 65%;
		padding-left: 30px;
		@media screen and (min-width: $medium-start) {
		    width: 40%;
		}
		.team-name {
		    display: flex;
		    margin-top: 3px;
		    color: $color-main-text;
            @media screen and (min-width: $medium-start) {
                margin-top: 0;
            }
		}
		.manager-name {
		    color: $color-secondary-text;
		}
	}
	&__cell-last {
		padding-right: 9px;
		text-align: center;
		>span {
			min-width: 100%;
			justify-content: center;
		}
	}
}

.leagues-board {
	display: flex;
	flex-direction: column;
	border: 2px solid $color-light-gray;
	box-shadow: 0 5px 30px rgba(0, 0, 0, 0.25);
	&.common, &.private, &.round-stat {
		.leagues-board__name, .leagues-board__points, .leagues-board__members {
			p {
				text-overflow: ellipsis;
				overflow: hidden;
				width: 100%;
			}
		}
		.leagues-board__name, .leagues-board__members {
			max-width: 100%;
		}
		.leagues-board__points {
			min-width: 100%;
			padding: 0;

			&.isPrivateLeague {
				.settings-btn {
					border: none;
					background: url('../assets/images/leave.svg') no-repeat;
					background-size: 20px auto;
					
					&.isOwnLeague {
						background: url('../assets/images/settings.svg') no-repeat;
						background-size: 20px auto;
					}

					&:hover {
						box-shadow: 0 0 15px $color-accent1;
						background-color: rgba(0, 143, 215, 0.35);
					}

				    margin-left: 15px;
					width: 25px;
					height: 20px;
					display: inline-block;
				}

				p {
					width: 80px;
					padding-left: 5px;
					@media screen and (min-width: $extra-large) {
						width: calc(100% - 25px);
					}
				}
			}
			

			p {
				text-align: end;
			}
		}
	}
	&.private {
		.private-leagues-scroll-wrapper {
			position: relative;
			&::before, &::after {
				content: url('../assets/images/modal-scroll.svg');
				position: absolute;
				z-index: 100;
				left:50%;
				width: 12px;
				height: 8px;
			}
			&::before {
				top: 20px;
				transform: translate(-50%, -50%) scaleY(-1);
			}
			&.isScrollOnTop {
				&::before {
					display: none;
				}
			}
			&::after {
				bottom: 10px;
				transform: translate(-50%, -50%);
			}
			&.isScrollOnBottom {
				&::after {
					display: none;
				}
			}
		}

		.leagues-board__list {
		    background-color: #f2f2f2;
		}

		.leagues-board__name, .leagues-board__members {
			@media screen and (max-width: 390px) {
				max-width: 150px;
				min-width: 150px;
			}
		}
	}
	&.round-stat {
		.leagues-board__name, .leagues-board__points {
			p {
				text-overflow: ellipsis;
				overflow: hidden;
				width: 100%;
			}
		}
		.leagues-board__name {
			max-width: 160px;
			min-width: 160px;
			p {
				margin-bottom: 0;
			}
		}
		.leagues-board__points {
			min-width: 100%;
			padding: 0;
			p {
				text-align: end;
			}
		}
	}
	&__members {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		word-break: break-all;
		word-wrap: break-word;
		@extend .font-m4;
		color: #B3B3B3;
		margin-top: 3px;
	}
	&_mt {
		margin-top: 40px;
		@media screen and (min-width: $large-start) {
			margin-top: 30px;
		}
	}
	&_mb-last {
		margin-bottom: 60px;
		@media screen and (min-width: $medium-start) {
			margin-bottom: 80px;
		}
		@media screen and (max-width: $small-end) {
			margin-top: 40px !important;
		}
	}
	&__header {
		padding: 20px 15px;
		height: 76px;
		margin: 0;
		@extend .font-h4new;
	}
	&__league-item:first-child {
		border-top: 2px solid $color-light-gray;
	}
	&__league-item:last-child {
		border-bottom: none;
	}
	&__league-item {
		list-style: none;
		padding: 12px 15px;
		border-bottom: 2px solid $color-light-gray;
		a {
			color: black;

			&.color-accent1 {
				color: $color-accent1;
			}
		}
		&.private {
		    background-color: white;
		}
	}
	&__name {
		@extend .font-m3;
		font-weight: 600 !important;
		line-height: 110% !important;
	}
	&__points {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		position: relative;
		@extend .font-h4;
	}

	&.round, &.season {
		.spinner {
			min-height: 220px;
		}
	}
}

.round-stat-line {
	width: 100%;
	 >div:last-child {
        padding: 0 15px 0 0;
	}
}

.round-stat-line>div {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.round-stat {
	.leagues-board__league-item {
		display: flex;
		justify-content: center;
		padding: 0 !important;
		color: inherit;
	}
	.leagues-board__points {
		min-width: 100px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		text-align: end;
	}
}

.top-player-section-name {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
}

.leagues-board__members {
	line-height: 15px !important;
	margin-top: 0px;
}


.copy-icon-wrapper {
	position: relative;

	.copy-text {
		pointer-events: none;
		line-height: 110%;
		padding: 10px;
		font-size: 18px;
		font-family: $font-family-bebas-neue;
		text-transform: uppercase;
		background-color: black;
		color: white;

		position: absolute;
		white-space: nowrap;
		bottom: -70px;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		border-radius: 6px;

		&.hide {
			animation: disapear 2s linear;
			opacity: 0;
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent transparent black;
		}


		@media screen and (max-width: $extra-small-end) {
			left: -100%;
			&:after {
				left: 76%;
			}
		}
	}

	&.in-modal {
		.copy-text {
			bottom: -70px;
			left: -120%;

			&:after {
				left: 79%;
			}
		}
	}

	@keyframes disapear {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 1;
		}
		99% {
			opacity: 0;
		}
		100% {
			display: none;
			opacity: 0;
		}
	}

	.copy-icon {
		cursor: pointer;
		width: 30px;
		height: 30px;
		margin-left: 5px;

		&:hover {
			box-shadow: 0 0 15px $color-accent1;
			background-color: rgba(0, 143, 215, 0.35);
		}
	}
}