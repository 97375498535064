.welcome {
  min-height: 150px;
  @media screen and (min-width: $medium-start) {
    min-height: 200px;
  }
  display: flex;
  align-items: center;
  @extend .bg-welcome;
  &__container {
    @media screen and (min-width: $medium-start) {
      padding: 0;
    }
  }
  &__text {
    padding-left: 15px;
    @extend .font-h4new; /* H4 */
    @media screen and (min-width: $medium-start) {
      font-size: 72px;
      padding: 0;
    }
    color: white;
    margin-bottom: 0;
    &_transparent {
      color: rgba(250, 250, 250, 0.5);
    }
  }
}
