.players-list-stats-next-round {
  min-height: 72px;
  max-width: 265px;
  width: 100%;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 0 !important;
  justify-content: space-between;
  @media screen and (max-width: $large-end) {
    width: 240px;
  }
  @media screen and (max-width: $small-end) {
    width: 80%;
    margin-bottom: 20px !important;
    min-height: 63px;
  }
}

.wildcard-reason {
  display: none;
}

.activated-wildcard {
  color: green !important;
  pointer-events: auto !important;
  position: relative;

  &:hover {
    border-color: #008FD7;
    background-color: white;
    color: #008FD7;
    +.wildcard-reason {
      line-height: 110%;
      padding: 10px;
      font-size: 18px;
      font-family: $font-family-bebas-neue;
      text-transform: uppercase;
      top: 130px;
      left: calc(50% - 140px);
      width: 280px;
      display: block;

      @media screen and (max-width: $small-end) {
        top: 120px;
        left: calc((100vw - 560px) / 2);
        width: 280px;
      }
      @media screen and (max-width: $extra-small-end) {
        top: 120px;
        left: calc(((15vw - 40px) / 2 ) + 21.25vw - 70px);
        width: 160px;
      }
      @media screen and (min-width: $large-start) {
        transform: translate(-50%, -50%);
        top: 100px;
        left: calc(25px + 13.5%);
        width: 190px;
      }
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black;
      }
    }
  }
}